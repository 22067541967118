import Card from "components/UI/Card";
import MainCard from "components/UI/MainCard";
import useDocumentTitle from "hooks/use-document-title";

import classes from "./Donations.module.css";

const Donations = () => {
  useDocumentTitle("Dons - Roll In' Ternet");

  return (
    <MainCard>
      <Card className={classes.donations}>
        <div className={classes.iframeContainer}>
          <iframe
            title="Dons pour Roll In' Ternet"
            allowtransparency="true"
            loading="lazy"
            scrolling="auto"
            src="https://www.helloasso.com/associations/roll-in-ternet/formulaires/1/widget"
          ></iframe>
        </div>
      </Card>
    </MainCard>
  );
};

export default Donations;
