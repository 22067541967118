import {
  discordId,
  isUserAdmin,
  isUserAuthenticated,
} from "providers/atoms/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Cookies from "universal-cookie";

const useLogout = () => {
  const setIsAuthenticated = useSetRecoilState(isUserAuthenticated);
  const setIsAdmin = useSetRecoilState(isUserAdmin);
  const setDiscordId = useSetRecoilState(discordId);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    const cookies = new Cookies();

    cookies.remove("rollinternet_token");
    localStorage.removeItem("oauth-state");
    setIsAuthenticated(false);
    setIsAdmin(false);
    setDiscordId(undefined);
    navigate("/");
  }, [navigate, setDiscordId, setIsAdmin, setIsAuthenticated]);

  return logout;
};

export default useLogout;
