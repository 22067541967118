import Banner from "components/UI/Banner";
import MainCard from "components/UI/MainCard";
import Merchandise from "./Merchandise";

import classes from "./MerchandisesCategories.module.css";

const MerchandiseCategories = (props) => {
  let sortedMerch;

  const compareMerch = (merchA, merchB) => {
    if (merchA.available && !merchB.available) return -1;

    if (!merchA.available && merchB.available) return 1;

    if (merchA.name.toLowerCase() < merchB.name.toLowerCase()) return -1;

    return 1;
  };

  if (props.merchandises) {
    sortedMerch = props.merchandises.sort(compareMerch);
  }

  return (
    <>
      <Banner className={classes.banner}>{props.type}</Banner>
      <MainCard>
        <ul className={classes.merchandisesContainer}>
          {sortedMerch.map((merch) => (
            <li key={merch.id}>
              <Merchandise merch={merch} />
            </li>
          ))}
        </ul>
      </MainCard>
    </>
  );
};

export default MerchandiseCategories;
