import Card from "components/UI/Card";
import MainCard from "components/UI/MainCard";
import { Link } from "react-router-dom";

import useDocumentTitle from "hooks/use-document-title";

import faqIcon from "assets/FAQ.png";
import programIcon from "assets/Programme.png";
import bannerRollIn from "assets/bannerweb.png";
import beginnerIcon from "assets/beginner.png";
import discordIcon from "assets/brands/Discord-Logo-Black.svg";
import { HashLink } from "react-router-hash-link";
import classes from "./Homepage.module.css";

import Banner from "components/UI/Banner";

const Homepage = () => {
  useDocumentTitle("Roll In' Ternet");

  return (
    <>
      <MainCard className={classes.card}>
        <div className={classes.bannerRollIn}>
          <img
            src={bannerRollIn}
            alt="La bannière de l'association Roll In' Ternet"
          />
        </div>
      </MainCard>

      <Banner className={classes.banner}>
        Le festival - Informations principales
      </Banner>

      <MainCard className={classes.mainInfoRow}>
        <Card className={classes.infoContainer}>
          <div className={classes.topDiv}>
            Le festival se déroulera sur notre serveur Discord&nbsp;!
            <br /> Rejoignez-le ici&nbsp;:
          </div>
          <div className={classes.bottomDiv}>
            <a href="http://www.discord.gg/jeTTYyF">
              <img src={discordIcon} alt="Logo discord" />
            </a>
          </div>
        </Card>

        <Card className={classes.infoContainer}>
          <div className={classes.topDiv}>
            La prochaine édition aura lieu en 2024&nbsp;!<br />
          </div>
          <div className={classes.bottomDiv}>
            <img src={programIcon} alt="Icone de calendrier" />
          </div>
        </Card>
        <Card className={classes.infoContainer}>
          <div className={classes.topDiv}>
            Il est ouvert à toutes et à tous, débutant.e.s comme habitué.e.s du
            jeu de rôle.
          </div>
          <div className={classes.bottomDiv}>
            <HashLink to="/faq#beginner">
              <img src={beginnerIcon} alt="Icone représentant l'inclusivité" />
            </HashLink>
          </div>
        </Card>

        <Card className={classes.infoContainer}>
          <div className={classes.topDiv}>
            Si vous vous posez d'autres questions, une super FAQ est
            disponible&nbsp;!
          </div>
          <div className={classes.bottomDiv}>
            <Link to="/faq">
              <img src={faqIcon} alt="Icone de FAQ" />
            </Link>
          </div>
        </Card>
      </MainCard>

      <Banner className={classes.banner}>
        Le jeu de rôle - De quoi on parle&nbsp;?
      </Banner>

      <MainCard>
        <Card className={classes.jdr}>
          <p>
            Le jeu de rôle, ou "jdr", c'est une activité ludique dans laquelle
            les joueuses et les joueurs incarnent un personnage dans un
            environnement fictif (fantastique ou réaliste), et le font évoluer
            par le biais d'un scénario raconté par un narrateur ou une
            narratrice&nbsp;! <br />
            <br />
            En anglais, ça s'appelle "role playing game", plus connu sous le nom
            de RPG. (et oui, c'est ce principe là qui est utilisé dans les jeux
            vidéos&nbsp;!).
          </p>
        </Card>

        <br />
        <br />
        <div className={classes.lanceToi}>
          <a href="http://www.discord.gg/jeTTYyF">Lance &nbsp;toi&nbsp;!</a>
        </div>
      </MainCard>
    </>
  );
};

export default Homepage;
