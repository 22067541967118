import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import classes from "./Dropdown.module.css";
import DropdownItem from "./DropdownItem";

const Dropdown = ({ path, title }) => {
  const location = useLocation().pathname;
  const [showMenu, setShowMenu] = useState(false);

  const [activePath, setActivePath] = useState(false);

  const showDropdown = (event: React.SyntheticEvent<HTMLElement>) => {
    setShowMenu(true);
  };

  const hideDropdown = () => {
    setShowMenu(false);
  };

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.FocusEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    setActivePath(location in path);
  }, [location, path]);

  return (
    // <Box
    //   onMouseEnter={(event) => showDropdown(event)}
    //   onMouseLeave={hideDropdown}
    // >
    //   <div
    //     onFocus={(event) => showDropdown(event)}
    //     className={activePath ? classes.titleActive : classes.title}
    //     tabIndex={0}
    //   >
    //     {title}
    //   </div>
    //   <Menu
    //     id="basic-menu"
    //     anchorEl={anchorEl}
    //     open={open}
    //     onClose={handleClose}
    //     MenuListProps={{
    //       "aria-labelledby": "basic-button",
    //     }}
    //     sx={{
    //       borderTopLeftRadius: "none",
    //       borderTopRightRadius: "none",
    //     }}
    //   >
    //     {Object.entries(path).map(([path, title], index) => (
    //       <MenuItem key={index} onClick={handleClose}>
    //         <DropdownItem
    //           path={path}
    //           title={title}
    //           activeClass={classes.active}
    //           onBlur={
    //             path === Object.keys(path)[Object.keys(path).length - 1]
    //               ? hideDropdown
    //               : null
    //           }
    //         />{" "}
    //       </MenuItem>
    //     ))}
    //   </Menu>
    // </Box>
    <div
      className={classes.dropdownMenu}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <div
        onFocus={showDropdown}
        className={activePath ? classes.titleActive : classes.title}
        tabIndex={0}
      >
        {title}
      </div>
      {showMenu && (
        <div className={classes.subMenu} onClick={hideDropdown}>
          <ul>
            {Object.entries(path).map(([path, title]) => (
              <li key={path}>
                <DropdownItem
                  path={path}
                  title={title}
                  activeClass={classes.active}
                  onBlur={
                    path === Object.keys(path)[Object.keys(path).length - 1]
                      ? hideDropdown
                      : null
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
