import { Alert, Snackbar } from "@mui/material";

interface RollInSnackbarProps {
  open: boolean;
  onClose: () => void;
  severity: SnackbarSeverity;
  text: string;
}

export enum SnackbarSeverity {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export const RollInSnackbar = ({
  open,
  onClose,
  severity,
  text,
}: RollInSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%" }}
        variant="filled"
        elevation={6}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};
