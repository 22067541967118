import useLogout from "hooks/use-logout";
import jwt_decode from "jwt-decode";
import {
  discordId,
  isUserAdmin,
  isUserAuthenticated,
} from "providers/atoms/auth";
import React, { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AuthToken, RollInToken } from "types/Tokens";
import Cookies from "universal-cookie";

const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const setIsAuthenticated = useSetRecoilState(isUserAuthenticated);
  const setIsAdmin = useSetRecoilState(isUserAdmin);
  const setDiscordId = useSetRecoilState(discordId);

  const navigate = useNavigate();
  const location = useLocation();
  const logoutHandler = useLogout();

  const loginHandler = useCallback(async () => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const code: string = params.get("code") ?? "";
    const state: string = decodeURIComponent(params.get("state") ?? "");

    if (code) {
      try {
        if (localStorage.getItem("oauth-state") !== state) {
          throw new Error("Erreur d'authentification");
        }

        const body = new URLSearchParams();
        body.append("code", code);
        body.append("grant_type", "authorization_code");
        body.append("redirect_uri", process.env.REACT_APP_REDIRECT_URI ?? "");
        body.append("scope", "");

        const oauthResult = await fetch(
          process.env.REACT_APP_API_URL + "/users/login/token/",
          {
            method: "POST",
            body: body,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (!oauthResult.ok) {
          throw new Error(oauthResult.status + " " + oauthResult.statusText);
        }

        const oauthData = await oauthResult.json();

        const decodedToken: AuthToken = jwt_decode(oauthData.access_token);

        const cookie = new Cookies();

        cookie.remove("rollinternet_token");
        cookie.set("rollinternet_token", oauthData, {
          path: "/",
          expires: new Date(decodedToken.exp * 1000),
          secure: true,
          sameSite: "strict",
        });
        setIsAuthenticated(true);
        setDiscordId(decodedToken.discord_id);
        setIsAdmin(decodedToken.is_superuser);
        //Redirect if new user
        if (oauthResult.status === 201) {
          navigate("/profil");
        }
      } catch (error) {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    }
  }, [location.search, setIsAuthenticated, setDiscordId, setIsAdmin, navigate]);

  // COMMENTED WHILE WEBSITE IS STATIC
  // useEffect(() => {
  //   const cookie = new Cookies();
  //   const token: RollInToken = cookie.get("rollinternet_token");

  //   if (token) {
  //     setIsAuthenticated(true);
  //     const decodedToken: AuthToken = jwt_decode(token.access_token);

  //     setIsAdmin(decodedToken.is_superuser);
  //   } else {
  //     loginHandler();
  //   }
  // }, [loginHandler, setIsAdmin, setIsAuthenticated]);

  // UNCOMMENTED WHILE WEBSITE IS STATIC
  useEffect(() => {
    const cookie = new Cookies();
    const token: RollInToken = cookie.get("rollinternet_token");

    if (token) {
      logoutHandler();
    }
  }, [logoutHandler]);

  return <>{children}</>;
};

export default AuthProvider;
