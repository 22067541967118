import { atom } from "recoil";
import { User } from "types/User";
import { AuthToken } from "types/Tokens";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

const getAuthCookie = () => {
  const cookies = new Cookies();
  return cookies.get("rollinternet_token");
};

const getAuthToken = () => {
  const authCookie = getAuthCookie();

  if (!authCookie) {
    return {
      is_superuser: false,
      discord_id: undefined,
    };
  }

  const decodedToken: AuthToken = jwt_decode(authCookie.access_token);

  return {
    is_superuser: decodedToken.is_superuser,
    discord_id: decodedToken.discord_id,
  };
};

export const isUserAuthenticated = atom<boolean>({
  key: "isUserAuthenticated",
  default: !!getAuthToken().discord_id,
});

export const isUserAdmin = atom<boolean>({
  key: "isUserAdmin",
  default: getAuthToken().is_superuser || false,
});

export const discordId = atom<string>({
  key: "discordId",
  default: getAuthToken().discord_id || undefined,
});

export const currentUserAtom = atom<User>({
  key: "currentUserAtom",
  default: undefined,
});
