import useDocumentTitle from "hooks/use-document-title";

import error404 from "assets/404.png";
import classes from "./NotFound.module.css";

const NotFound = () => {
  useDocumentTitle("Cette page n'existe pas");

  return (
    <img
      src={error404}
      alt="Erreur 404 - La page demandée n'existe pas"
      className={classes.img}
    />
  );
};

export default NotFound;
