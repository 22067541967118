import Card from "components/UI/Card.js";
import MainCard from "components/UI/MainCard.js";
import useDocumentTitle from "hooks/use-document-title.js";
import { Link } from "react-router-dom";

import classes from "./Faq.module.css";

const Faq = () => {
  useDocumentTitle("Foire aux Questions - Roll In' Ternet");

  return (
    <MainCard>
      <h1>Foire aux questions</h1>
      <Card className={classes.faq}>
        <ul>
          <h2>Informations générales</h2>
          <li>
            <p>
              <i>Quelles sont les dates du festival&nbsp;?</i>
              <br />
              La prochaine édition de Roll In' Ternet aura lieu en 2024&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <i>Où le festival a-t-il lieu&nbsp;?</i>
              <br />
              Le festival se déroule entièrement en ligne, sur{" "}
              <a href="http://www.discord.gg/jeTTYyF">notre serveur Discord</a>.
            </p>
          </li>
          <li>
            <p>
              <i>Où peut-on trouver des informations sur le festival&nbsp;?</i>
              <br />
              Ce site réunit toutes les informations principales du festival.
              Toutes les actualités, le programme et les événements sont
              annoncés sur nos différents{" "}
              <Link to="/contact">réseaux sociaux</Link>, ainsi que sur le salon
              #annonces du serveur Discord.
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2>Contacter l'équipe d'organisation</h2>
          <li>
            <p>
              <i>
                J'ai une question dont la réponse n'est pas écrite dans cette
                FAQ
              </i>
              <br />
              Vous pouvez nous poser votre question sur le{" "}
              <a href="http://www.discord.gg/jeTTYyF">serveur Discord</a> du
              festival (n'hésitez pas à identifier l'équipe de modération en
              taguant les @mainsdelajustice), en public ou en privé sur nos{" "}
              <Link to="/contact">réseaux sociaux</Link>, ou à nous contacter
              par mail à l'adresse suivante&nbsp;:{" "}
              <a href="mailto:contact@rollinternet.fr">
                contact@rollinternet.fr
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              <i>Je souhaite contacter l'équipe en privé</i>
              <br />
              Il vous suffit d'envoyer votre message à une personne de l'équipe
              de modération du{" "}
              <a href="http://www.discord.gg/jeTTYyF">serveur Discord</a>{" "}
              (l'équipe s'appelle les "Mains de la Justice"), de nous envoyer un
              message privé sur l'un de nos{" "}
              <Link to="/contact">réseaux sociaux</Link> ou bien de nous envoyer
              un mail à l'adresse suivante&nbsp;:{" "}
              <a href="mailto:contact@rollinternet.fr">
                contact@rollinternet.fr
              </a>
              .
            </p>
          </li>
          <li className={classes.faqBlock}>
            <p>
              <i>Je voudrais proposer un partenariat à Roll In' Ternet</i>
              <br />
              Vous pouvez nous envoyer un mail à l'adresse suivante&nbsp;:{" "}
              <a href="mailto:contact@rollinternet.fr">
                contact@rollinternet.fr
              </a>
              , ou bien contacter directement Shanori.
            </p>
          </li>
          <li>
            <p>
              <i>Je souhaite proposer une partie de jeu en tant que MJ</i>
              <br />
              Nous ne prenons pas encore de MJ pour la prochaine édition&nbsp;!
              Et nous n'avons pas de système de recrutement ouvert, ce qui
              explique pourquoi vous n'avez certainement pas vu de message vous
              invitant à vous inscrire.
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2 id="beginner">Je débute dans le jeu de rôle</h2>
          <li>
            <p>
              <i>
                Est-ce que je peux participer au festival si je n'ai jamais fait
                de jeu de rôle&nbsp;?
              </i>
              <br />
              Tu peux tout à fait participer à Roll In' Ternet si tu n'as jamais
              fait de partie. A vrai dire, nous avons imaginé ce festival comme
              un lieu et un moment faits pour découvrir et expérimenter le jeu
              de rôle.
            </p>
          </li>
          <li>
            <p>
              <i>
                Y-a-t-il des parties prévues en particulier pour les
                novices&nbsp;?
              </i>
              <br />
              Nous avons vraiment à coeur de permettre à tout le monde de
              découvrir le jeu de rôle. Ainsi, chaque MJ qui propose une table
              lors du festival s'attend à ce que des personnes n'ayant jamais
              fait de jeu de rôle (ou très peu) s'inscrivent à son scénario.
              Donc 100% des parties sont accessibles aux novices&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <i>
                Comment est-ce que je dois me préparer pour une partie&nbsp;?
              </i>
              <br />
              Tu dois tout d'abord te rendre sur{" "}
              <a href="http://www.discord.gg/jeTTYyF">notre serveur Discord</a>
              , lire et approuver le règlement. Ensuite, tu dois vérifier que
              ton microphone et ton son fonctionnent bien.
              <br />
              Si ta partie a lieu entièrement sur Discord, tu peux aller lire
              les règles de lancer de dés (sur le salon #tuto-dés)&nbsp;: pas de
              panique si tu ne comprends pas tout, les autres personnes à ta
              table pourront te ré-expliquer&nbsp;!
              <br />
              Si ta partie utilise une Table Virtuelle, tu n'a pas besoin
              d'aller lire les règles de lancer de dés, mais tu dois par contre
              très certainement aller te créer un compte sur la plateforme de la
              Table Virtuelle.
              <br />
              Quelques jours avant ta partie, tu pourras lire le salon textuel
              discord de ta table&nbsp;: ton ou ta MJ te communiquera
              certainement des informations, comme par exemple le choix de ton
              personnage&nbsp;!
              <br />
              Et enfin, une partie dure souvent un moment, alors avant de jouer
              pense à prendre de quoi boire&nbsp;!
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2>Événements et activités</h2>
          <li>
            <p>
              <i>Où puis-je trouver le programme du festival&nbsp;?</i>
              <br />
              Le programme de la prochaine édition n'existe pas encore. Pour te
              donner une idée du festival, tu peux consulter le programme de la
              cinquième édition à <Link to="/program">cette adresse</Link>&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <i>Y a-t-il des activités avant le jour du festival&nbsp;?</i>
              <br />
              Oui&nbsp;! L'équipe animation proposera différentes activité avant
              le jour-J, sur le{" "}
              <a href="http://www.discord.gg/jeTTYyF">serveur Discord</a>.
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2>Tables de jeu et parties de jeu de rôle</h2>
          <li>
            <p>
              <i>Où puis-je trouver la liste des parties proposées&nbsp;?</i>
              <br />
              La liste des parties n'est pas encore disponible.
              Elle le sera en 2024&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <i>Je souhaite participer à une séance de jeu</i>
              <br />
              Les inscriptions aux parties de jeu ne sont pas ouvertes. Il
              sera possible de s'inscrire quelques semaines avant le
              festival&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <i>Où les parties de jeu de rôle auront-elles lieu&nbsp;?</i>
              <br />
              Les parties auront lieu sur{" "}
              <a href="http://www.discord.gg/jeTTYyF">notre serveur Discord</a>.
              Elles se dérouleront soit entièrement là-bas, dans des salons
              écrits et vocaux dédiés, soit l'utilisation des salons vocaux sera
              complétée par l'utilisation d'une Table Virtuelle, dite "VTT"
              (définition plus bas).
            </p>
          </li>
          <li>
            <p id="trigger">
              <i>Que veut dire le mot "trigger(s)"&nbsp;?</i>
              <br />
              Un trigger correspond à un mot clé ou à une situation qui peut
              affecter mentalement une personne qui s'y retrouve confrontée.
              Ici, nous utilisons deux types de triggers&nbsp;: les avertissements
              de contenu et les triggers des joueuses et des joueurs.
              <br />
              Les avertissements de contenu, ce sont les situations négatives
              auxquelles les joueuses et les joueurs risquent d'être confrontées
              lors de la partie de jeu (exemple&nbsp;: la partie "L'arbre du
              destin" contient les triggers&nbsp;: torture, araignées et sang.
              En jouant à ce scénario, vous savez donc que vous risquez d'être
              confronté.e.s à ces éléments).
              <br />
              Les triggers des joueuses et des joueurs, ce sont les situations
              auxquelles les participant.e.s ne souhaitent pas être
              confronté.es, et qui ne se trouvent pas dans la liste annoncée des
              avertissements de contenu (exemple&nbsp;: Louis décide de participer à
              la partie "L'arbre du destin" et il n'a aucun problème à être
              confronté à de la torture, du sang ou des araignées. Par contre,
              il n'a pas envie d'être confronté à du sexisme, ni à de la
              violence envers les enfants. Il peut donc indiquer en plus ses
              triggers, afin qu'aucune autre personne présente à sa table ne
              crée cette situation).
            </p>
          </li>
          <li>
            <p>
              <i>
                Comment indiquer mes triggers à ma table de jeu de manière
                anonyme&nbsp;?
              </i>
              <br />
              Vous pouvez indiquer dans votre profil sur ce site quels sont vos
              triggers personnels&nbsp;: ces derniers seront communiqués de
              manière anonyme aux MJ des parties auxquelles vous aller
              participer. Expliqué autrement, les MJ vont recevoir la liste
              complète des triggers des joueuses et des joueurs de leurs tables,
              sans savoir lesquels sont à qui.
            </p>
          </li>
          <li>
            <p>
              <i>
                Le renseignement de mes triggers dans mon profil est-il
                anonyme&nbsp;?
              </i>
              <br />
              Une personne seulement peut avoir accès à vos triggers, pour
              des raisons techniques&nbsp;: Irio, et uniquement en accédant à
              la base de données (ce qui normalement n'arrivera pas).
              <br />
              Nous nous engageons à ne jamais communiquer vos triggers associés
              à votre nom ou votre pseudo, et à ne pas aller voir votre profil
              dans la base de données sauf nécessité technique.
            </p>
          </li>
          <li>
            <p>
              <i>
                L'utilisation de triggers est-elle vraiment nécessaire&nbsp;?
              </i>
              <br />
              Le but du jeu de rôle étant de prendre du plaisir, oui, leur
              utilisation est nécessaire. Tout le monde a le droit de passer un
              bon moment en toute sécurité&nbsp;! Il ne faut pas oublier que
              lors d'un festival, on ne connaît pas les personnes avec qui l'on
              joue; on ne connaît donc pas ce qu'elles ont vécues, ni leur
              sensibilité. C'est le devoir de chaque personne participant à Roll
              In' Ternet de prendre du plaisir, et en s'amusant de contribuer au
              bien-être des autres&nbsp;! Et les triggers sont une grande aide à
              ça&nbsp;!
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2>Déroulement des parties</h2>
          <li>
            <p>
              <i>Heure de rendez-vous</i>
              <br />
              Pour le bon déroulement des parties, nous vous demandons de vous
              rendre dans le salon vocal de votre table 10 minutes avant le
              début de votre séance.
            </p>
          </li>
          <li>
            <p>
              <i>Comment dois-je me préparer&nbsp;?</i>
              <br />
              Tu dois tout d'abord te rendre sur{" "}
              <a href="http://www.discord.gg/jeTTYyF">notre serveur Discord</a>
              , lire et approuver le règlement. Ensuite, tu dois vérifier que
              ton microphone et ton son fonctionnent bien.
              <br />
              Si ta partie a lieu entièrement sur Discord, tu peux aller lire
              les règles de lancer de dés (sur le salon #tuto-dés).
              <br />
              Si ta partie utilise une Table Virtuelle, tu n'a pas besoin
              d'aller lire les règles de lancer de dés, mais tu dois par contre
              très certainement aller te créer un compte sur la plateforme de la
              Table Virtuelle.
              <br />
              Quelques jours avant ta partie, tu pourras lire le salon textuel
              discord de ta table&nbsp;: ton ou ta MJ te communiquera
              certainement des informations, comme par exemple le choix de ton
              personnage&nbsp;!
              <br />
              Et enfin, une partie dure souvent un moment, alors avant de jouer
              pense à prendre de quoi boire&nbsp;!
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classes.faq}>
        <ul>
          <h2>Tables Virtuelles (VTT&nbsp;: Virtual TableTop)</h2>
          <li>
            <p>
              <i>Une VTT&nbsp;: qu'est-ce que c'est&nbsp;?</i>
              <br />
              C'est une plateforme sur internet qui permet de faire du jeu de
              rôle, et rend notamment possible l'utilisation de cartes, de
              pions, et de fiches de personnages.
            </p>
          </li>
          <li>
            <p>
              <i>
                La partie de jdr à laquelle je me suis inscrit.e utilise une VTT
              </i>
              <br />
              Cela signifie que votre MJ va, tout en communiquant avec vous sur
              Discord, vous faire jouer par cette plateforme. Il vous faut donc
              créer un compte sur le site de VTT avant le début de votre session
              (à part pour FoundyVTT, pas besoin de créer de compte&nbsp;!).
              Vous pouvez accéder au site de votre Table Virtuelle par les liens
              indiqués en réponse à la question ci-dessous.
            </p>
          </li>
          <li>
            <p>
              <i>Quelles sont les VTT utilisées durant ce festival&nbsp;?</i>
              <br />
              Durant Roll In' Ternet, les MJ vont utiliser les Tables Virtuelles
              suivantes&nbsp;:
              <br />- <a href="https://roll20.net/">Roll 20</a>
              <br />- <a href="https://foundryvtt.com/">Foundry VTT</a> (pas
              besoin de créer un compte)
            </p>
          </li>
          <li>
            <p>
              <i>Comment mettre Roll 20 en Français&nbsp;?</i>
              <br />
              Le site est, de base, en anglais. Tout d'abord, créez-vous un
              compte. Puis, une fois que votre compte est créé, connectez-vous,
              et suivez la démarche ci-dessous&nbsp;:
              <br />
              Cliquez sur votre pseudo en haut à droite
              <br />
              → My Account
              <br />
              → Preferences
              <br />→ Language ; et sélectionnez le français.
            </p>
          </li>
        </ul>
      </Card>
    </MainCard>
  );
};

export default Faq;
