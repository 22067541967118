import Card from "components/UI/Card";
import useDocumentTitle from "hooks/use-document-title";

import discordIcon from "assets/brands/Discord-Logo-Black.svg";
import instagramIcon from "assets/brands/Instagram-Logo.png";
import twitchIcon from "assets/brands/TwitchGlitchBlackOps.svg";
import twitterIcon from "assets/brands/Twitter-Logo-black.svg";
import facebookIcon from "assets/brands/f_logo_RGB-Black_58.png";
import youtubeIcon from "assets/brands/youtube_social_icon_dark.png";
import mailIcon from "assets/mailIcon.png";
import classes from "./Contact.module.css";

import MainCard from "components/UI/MainCard";

const Contact = () => {
  useDocumentTitle("Contact - Roll In' Ternet");

  return (
    <MainCard>
      <h1>Contact</h1>
      <Card className={classes.contact}>
        <p>
          Vous souhaitez rejoindre le festival&nbsp;? Il a lieu à cette
          adresse&nbsp;:
        </p>
        <ul>
          <li>
            <img src={discordIcon} alt="Icone de Discord" />
            <a href="http://www.discord.gg/jeTTYyF">Discord</a>
          </li>
        </ul>
      </Card>
      <Card className={classes.contact}>
        <p>
          Vous souhaitez contacter l'équipe de Roll In' Ternet, ou bien suivre
          notre actualité&nbsp;? C'est par ici&nbsp;!
        </p>
        <ul>
          <li>
            <img src={mailIcon} alt="Icone de mail" />
            <a href="mailto:contact@rollinternet.fr">contact@rollinternet.fr</a>
          </li>
          <li>
            <img src={twitterIcon} alt="Icone de Twitter" />
            <a href="https://twitter.com/RollInTernet">Twitter</a>
          </li>
          <li>
            <img src={instagramIcon} alt="Icone de Instagram" />
            <a href="https://www.instagram.com/rollinternet/">Instagram</a>
          </li>
          <li>
            <img src={facebookIcon} alt="Icone de Facebook" />
            <a href="https://www.facebook.com/RollInTernet">Facebook</a>
          </li>
          {/* <li>
            <img src={tiktokIcon} alt="Icone de TikTok" />
            <a href="https://www.tiktok.com/@rollinternet">TikTok</a>
          </li> */}
        </ul>
      </Card>
      <Card className={classes.contact}>
        <p>
          Vous souhaitez accéder à notre contenu vidéo, ainsi qu'à certains
          événements de nos dernières éditions&nbsp;? Voilà nos liens&nbsp;!
        </p>
        <ul>
          <li>
            <img src={youtubeIcon} alt="Icone de YouTube" />
            <a href="https://www.youtube.com/channel/UCQkF44bdCMkZVPPNPyD0nug">
              Youtube{" "}
            </a>
          </li>
          <li>
            <img src={twitchIcon} alt="Icone de Twitch" />
            <a href="https://www.twitch.tv/rollinternet">Twitch </a>
          </li>
        </ul>
      </Card>
    </MainCard>
  );
};

export default Contact;
