import classes from "./DownloadLink.module.css";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

const DownloadLink = ({ title, description, link }) => {
  return (
    <div className={classes.downloadLink}>
      <h2>{title}</h2>
      <p>{description}</p>
      <Button
        variant="contained"
        startIcon={<Download />}
        sx={{ marginLeft: "24px" }}
      >
        <a href={link} download>
          Télécharger
        </a>
      </Button>
    </div>
  );
};

export default DownloadLink;
