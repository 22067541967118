import { RollInSnackbar } from "components/UI/RollInSnackbar";
import { alertsAtom } from "providers/atoms/alerts";
import { FC } from "react";
import { useRecoilState } from "recoil";

export const SnackbarProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [alert, setAlert] = useRecoilState(alertsAtom);

  const handleClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <>
      <RollInSnackbar
        open={alert.open}
        onClose={handleClose}
        text={alert.text}
        severity={alert.severity}
      />
      {children}
    </>
  );
};
