export const staticData = {
  "Porte-clés": [
    {
      name: "Le porte-clés violet des 4",
      img_path: "/files/Le porte-clés violet des 4.jpg",
      price: 10.0,
      available: false,
      merchandise_type: "Porte-clés",
      created_at: "2022-05-09T15:37:57.634659+00:00",
      updated_at: "2023-05-29T10:25:54.737718+00:00",
      id: 41,
    },
    {
      name: "Le porte-clés violet des 10",
      img_path: "/files/Le porte-clés violet des 10.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Porte-clés",
      created_at: "2022-05-09T15:37:56.987884+00:00",
      updated_at: "2023-05-27T14:43:36.042642+00:00",
      id: 33,
    },
    {
      name: "Le porte-clés noir des 4",
      img_path: "/files/Le porte-clés noir des 4.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Porte-clés",
      created_at: "2022-05-09T15:37:55.713874+00:00",
      updated_at: "2023-05-27T14:43:29.277469+00:00",
      id: 20,
    },
    {
      name: "Le porte-clés violet des 100",
      img_path: "/files/Le porte-clés violet des 100.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Porte-clés",
      created_at: "2022-05-09T15:37:56.126937+00:00",
      updated_at: "2023-05-27T14:43:30.679650+00:00",
      id: 24,
    },
    {
      name: "Le porte-clés noir des 12",
      img_path: "/files/Le porte-clés noir des 12.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Porte-clés",
      created_at: "2022-05-09T15:37:58.282547+00:00",
      updated_at: "2023-05-27T14:43:42.393976+00:00",
      id: 48,
    },
  ],
  "Colliers à chaîne": [
    {
      name: "La chaîne des 4",
      img_path: "/files/La chaîne des 4.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:54.430182+00:00",
      updated_at: "2023-05-27T14:43:23.538099+00:00",
      id: 6,
    },
    {
      name: "La chaîne des 8",
      img_path: "/files/La chaîne des 8.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:56.695942+00:00",
      updated_at: "2023-05-27T14:43:35.224518+00:00",
      id: 30,
    },
    {
      name: "La chaîne des 100",
      img_path: "/files/La chaîne des 100.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:55.356847+00:00",
      updated_at: "2023-05-27T14:43:28.021425+00:00",
      id: 16,
    },
    {
      name: "La chaîne des sang d'encre",
      img_path: "/files/La chaîne des sang d'encre.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:56.557770+00:00",
      updated_at: "2023-05-27T14:43:34.361710+00:00",
      id: 28,
    },
    {
      name: "La chaîne des 6",
      img_path: "/files/La chaîne des 6.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:56.628282+00:00",
      updated_at: "2023-05-27T14:43:34.719779+00:00",
      id: 29,
    },
    {
      name: "La chaîne des 12",
      img_path: "/files/La chaîne des 12.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à chaîne",
      created_at: "2022-05-09T15:37:58.756079+00:00",
      updated_at: "2023-05-27T14:43:44.086947+00:00",
      id: 54,
    },
  ],
  "Colliers à cordon": [
    {
      name: "Le cordon des dilemmes",
      img_path: "/files/Le cordon des dilemmes.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:54.575745+00:00",
      updated_at: "2023-05-27T14:43:23.963874+00:00",
      id: 7,
    },
    {
      name: "Le ctalisman protecteur",
      img_path: "/files/Le ctalisman protecteur.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:55.107071+00:00",
      updated_at: "2023-05-27T14:43:27.509495+00:00",
      id: 13,
    },
    {
      name: "Le cordon des 4",
      img_path: "/files/Le cordon des 4.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:55.641152+00:00",
      updated_at: "2023-05-27T14:43:28.862937+00:00",
      id: 19,
    },
    {
      name: "Le cordon des 6",
      img_path: "/files/Le cordon des 6.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:55.959849+00:00",
      updated_at: "2023-05-27T14:43:30.137138+00:00",
      id: 22,
    },
    {
      name: "Le cordon des 20",
      img_path: "/files/Le cordon des 20.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:56.357750+00:00",
      updated_at: "2023-05-27T14:43:33.873185+00:00",
      id: 26,
    },
    {
      name: "Le cordon des 12",
      img_path: "/files/Le cordon des 12.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:57.745353+00:00",
      updated_at: "2023-05-27T14:43:40.163105+00:00",
      id: 42,
    },
    {
      name: "Le cordon pew-pew",
      img_path: "/files/Le cordon pew-pew.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:57.845713+00:00",
      updated_at: "2023-05-27T14:43:40.466136+00:00",
      id: 43,
    },
    {
      name: "Le cordon des 10",
      img_path: "/files/Le cordon des 10.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:58.007641+00:00",
      updated_at: "2023-05-27T14:43:41.111814+00:00",
      id: 45,
    },
    {
      name: "Le cordon des 8",
      img_path: "/files/Le cordon des 8.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Colliers à cordon",
      created_at: "2022-05-09T15:37:58.346481+00:00",
      updated_at: "2023-05-27T14:43:42.802655+00:00",
      id: 49,
    },
  ],
  "Boucles d'oreilles": [
    {
      name: "Les boucles de fer",
      img_path: "/files/Les boucles de fer.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:56.870653+00:00",
      updated_at: "2023-05-27T14:43:35.638959+00:00",
      id: 32,
    },
    {
      name: "Les boucles du sombre destin",
      img_path: "/files/Les boucles du sombre destin.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:55.853660+00:00",
      updated_at: "2023-05-27T14:43:29.712977+00:00",
      id: 21,
    },
    {
      name: "Les boucles des 6",
      img_path: "/files/Les boucles des 6.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:57.310200+00:00",
      updated_at: "2023-05-27T14:43:36.457628+00:00",
      id: 37,
    },
    {
      name: "Mes boucles et ma hache !",
      img_path: "/files/Mes boucles et ma hache !.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:57.526458+00:00",
      updated_at: "2023-05-27T14:43:37.375961+00:00",
      id: 40,
    },
    {
      name: "Les boucles des sang d'encre",
      img_path: "/files/Les boucles des sang d'encre.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:58.105964+00:00",
      updated_at: "2023-05-27T14:43:41.537075+00:00",
      id: 46,
    },
    {
      name: "Les boucles des 12",
      img_path: "/files/Les boucles des 12.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:58.588258+00:00",
      updated_at: "2023-05-27T14:43:43.202357+00:00",
      id: 52,
    },
    {
      name: "Les boucles de l'océan",
      img_path: "/files/Les boucles de l'océan.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:58.663339+00:00",
      updated_at: "2023-05-27T14:43:43.638977+00:00",
      id: 53,
    },
    {
      name: "Les boucles des mercenaires",
      img_path: "/files/Les boucles des mercenaires.jpg",
      price: 10.0,
      available: true,
      merchandise_type: "Boucles d'oreilles",
      created_at: "2022-05-09T15:37:58.821868+00:00",
      updated_at: "2023-05-27T14:43:45.531508+00:00",
      id: 55,
    },
  ],
  Autocollant: [
    {
      name: "Spetsou !",
      img_path: "/files/StickerSpetsou.png",
      price: 1.0,
      available: true,
      merchandise_type: "Autocollant",
      created_at: "2022-06-08T10:48:48.008592+00:00",
      updated_at: "2023-05-27T14:43:46.262645+00:00",
      id: 59,
    },
  ],
};
