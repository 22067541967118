import { NavLink } from "react-router-dom";

const DropdownItem = (props) => {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? props.activeClass : "")}
      to={props.path}
      tabIndex="0"
      onBlur={props.onBlur}
    >
      {props.title}
    </NavLink>
  );
};

export default DropdownItem;
