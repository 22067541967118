import MerchandisesList from "components/Shop/MerchandisesList";
import Banner from "components/UI/Banner";
import Card from "components/UI/Card";
import LoadingSpinner from "components/UI/LoadingSpinner";
import MainCard from "components/UI/MainCard";
import useDocumentTitle from "hooks/use-document-title";
import useFetch from "hooks/use-fetch";

import classes from "./Shop.module.css";
import { staticData } from "components/Shop/utils";

const Shop = () => {
  useDocumentTitle("Boutique - Roll In' Ternet");

  // COMMENTED TO STOP API CALLS
  //const { apiData, isLoading, serverError } = useFetch({
  //   url: "/merchandises/",
  // });

  let content = <MerchandisesList merchandises={staticData} />;

  // COMMENTED TO STOP API CALLS
  // if (serverError) {
  //   content = <p className={classes.error}>{serverError.message}</p>;
  // }

  // if (isLoading) {
  //   content = (
  //     <MainCard>
  //       <div className={classes.loading}>
  //         <LoadingSpinner />
  //       </div>
  //     </MainCard>
  //   );
  // }

  return (
    <>
      <MainCard className={classes.shopIntro}>
        <h1>Boutique</h1>
        {/* <div className={classes.shopIntro}> */}
        <p>
          En achetant des produits Roll In' Ternet X Frimaë la rôliste et en
          faisant un don, vous soutenez notre festival et faites vivre
          l'artisanat français&nbsp;!
          <br />
          Merci, vous êtes les meilleur.e.s ❤️
        </p>
        {/* </div> */}
      </MainCard>

      {content}
      <Banner className={classes.banner}>Commander</Banner>
      <MainCard>
        <p className={classes.shopText}>
          Notez-bien&nbsp;!
          <br />
          🌿 Lors du choix du modèle (exemple&nbsp;:&nbsp;"Le cordon des 8"),
          merci de cocher un nombre de produits égal au nombre de produits que
          vous achetez (exemple&nbsp;:&nbsp;j'achète 3 porte-clés, je ne
          sélectionne que trois modèles parmi tous ceuxproposés).
          <br />
          🌿 Il faut entrer un prix minimum de 1€ pour l'autocollant Spetsou !
        </p>
        <Card className={classes.shopIframe}>
          <iframe
            id="haWidget"
            title="Boutique de Roll In' Ternet"
            allowtransparency="true"
            loading="lazy"
            scrolling="auto"
            src="https://www.helloasso.com/associations/roll-in-ternet/evenements/festival-roll-in-ternet/widget"
          ></iframe>
        </Card>
      </MainCard>
      <a href="#haWidget">
        <button type="button" className={classes.shopButton}>
          Commander
        </button>
      </a>
    </>
  );
};

export default Shop;
