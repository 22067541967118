import planningDimanche from "assets/planning-dimanche.png";
import planningEvents from "assets/planning-events.png";
import planningSamedi from "assets/planning-samedi.png";
import planningVendredi from "assets/planning-vendredi.png";

import useDocumentTitle from "hooks/use-document-title";
import classes from "./Program.module.css";

import MainCard from "components/UI/MainCard";

const Previous = () => {
  useDocumentTitle("Programme - Roll In' Ternet");

  return (
    <MainCard>
      <h1>Programme de la 5ème édition</h1>
      <br />
      <div className={classes.program}>
        <img src={planningEvents} alt="Planning des évènements" />
        <img src={planningVendredi} alt="Planning des tables du Vendredi" />
        <img src={planningSamedi} alt="Planning des tables du Samedi" />
        <img src={planningDimanche} alt="Planning des tables du Dimanche" />
      </div>
    </MainCard>
  );
};

export default Previous;
