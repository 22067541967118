import Dropdown from "components/UI/Dropdown/Dropdown";
import { isUserAdmin } from "providers/atoms/auth";
import { useRecoilValue } from "recoil";

const FestivalDropdown = () => {
  const isAdmin = useRecoilValue(isUserAdmin);

  const pathUser = {
    "/": "Informations",
    "/program": "L'édition précédente",
    // "/tables": "Parties et activités",
    "/faq": "Foire aux questions",
    "/downloads": "Téléchargements",
    // "/previous": "L'édition précédente",
  };

  const pathAdmin = {
    "/": "Informations",
    "/program": "L'édition précédente",
    // "/tables": "Parties et activités",
    "/faq": "Foire aux questions",
    "/downloads": "Téléchargements",
    // "/previous": "L'édition précédente",
  };
  if (isAdmin) {
    return <Dropdown path={pathAdmin} title={"Le festival"} />;
  }

  return <Dropdown path={pathUser} title={"Le festival"} />;
};

export default FestivalDropdown;
