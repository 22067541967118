import useDocumentTitle from "hooks/use-document-title";

import DownloadLink from "components/UI/DownloadLink";
import MainCard from "components/UI/MainCard";

import classes from "./Downloads.module.css";

const Downloads = () => {
  useDocumentTitle("Téléchargements - Roll In' Ternet");

  return (
    <MainCard classes={classes.download}>
      <h1>Téléchargements</h1>
      <DownloadLink
        link="./files/pdf/La_Voie_du_Fer.pdf"
        title="La Voie du Fer - Un scénario d'enquête western"
        description="Écrit spécialement pour les MJ qui débutent. Un système de jeu ultra simple créé pour l'occasion est intégré !"
      />
      <DownloadLink
        link="./files/pdf/Rouge_comme_la_rose_.pdf"
        title="Rouge comme la Rose - Un scénario d'enquête fantastique"
        description="Également créé à l'adresse des MJ novices. Cette fois-ci, le système de jeu est celui de Chroniques Oubliées : Fantasy."
      />
      <DownloadLink
        link="./files/pdf/Le_Sommet_de_lAnse.pdf"
        title="Le Sommet de l'Anse"
        description="Créé collaborativement durant la 5ème édition de Roll In' Ternet. Pourra être suivi d'une campagne."
      />
    </MainCard>
  );
};

export default Downloads;
