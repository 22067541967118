import { createTheme, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { RecoilRoot } from "recoil";

const theme = createTheme({
  palette: {
    primary: {
      main: "#662490",
      light: "red",
    },
    secondary: {
      main: "#D40D8E",
    },
    info: {
      main: "#662490",
    },
  },
  typography: {
    fontFamily: "Raleway",
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
