import Dropdown from "components/UI/Dropdown/Dropdown";

const ShopDropdown = () => {
  const path = {
    "/shop": "La boutique",
    "/donations": "Faire un don",
  };

  return <Dropdown title={"Nous soutenir"} path={path} />;
};

export default ShopDropdown;
