import Card from "components/UI/Card";
import MainCard from "components/UI/MainCard";
import useDocumentTitle from "hooks/use-document-title";

import classes from "./Mentions.module.css";

const Mentions = () => {
  useDocumentTitle("Mentions légales - Roll In' Ternet");

  return (
    <MainCard>
      <h1>Mentions légales</h1>
      <Card className={classes.mentions}>
        <h2>Cookies</h2>
        <p>
          Roll In' Ternet n'utilise qu'un cookie, essentiel au bon
          fonctionnement du site&nbsp;:
          <br />
          rollinternet_token&nbsp;: Permet de rester connecté au site.
        </p>
      </Card>
      <Card className={classes.mentions}>
        <h2>Hébergement</h2>

        <p>
          Dénomination sociale&nbsp;: Vercel Inc.
          <br />
          Siège social&nbsp;: 340 S Lemon Ave #4133
          <br />
          Walnut CA 91789
          <br />
          United States of America
          <br />
          Téléphone&nbsp;: +1 951-383-6898
          <br />
          Site web&nbsp;: https://vercel.com/
          <br />
          Région d'opération&nbsp;: Paris, France
        </p>
      </Card>
      <Card className={classes.mentions}>
        <h2>Autres informations </h2>
        <p>
          Directrice de la publication&nbsp;: Sasha Burlet, Présidente de
          l'association
          <br />
          Roll In' Ternet est une association Loi 1901 dont le siège social se
          situe au&nbsp;:
          <br />
          1 chemin des renardières
          <br />
          64230 Sauvagnon
          <br />
          France
        </p>
      </Card>
    </MainCard>
  );
};

export default Mentions;
