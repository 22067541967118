import { NavLink } from "react-router-dom";

import logo from "assets/logo_couleur_ombre.png";
import { isUserAuthenticated } from "providers/atoms/auth";
import uuid from "react-uuid";
import { useRecoilValue } from "recoil";
import FestivalDropdown from "./FestivalDropdown";
import classes from "./Header.module.css";
import ShopDropdown from "./ShopDropdown";

const Header = () => {
  const isAuthenticated = useRecoilValue(isUserAuthenticated);
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const redirect_uri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);

  const loginHandler = () => {
    const state = uuid();

    const url =
      "https://discord.com/api/oauth2/authorize?response_type=code&client_id=" +
      client_id +
      "&scope=identify%20guilds.join&state=" +
      encodeURIComponent(state) +
      "&redirect_uri=" +
      redirect_uri +
      "&prompt=consent";

    localStorage.setItem("oauth-state", state);

    window.location.href = url;
  };

  return (
    <header className={classes.header}>
      <nav className={classes.nav}>
        <ul className={classes.mainColumn}>
          <li className={classes.logoContainer}>
            <NavLink className={classes.imgContainer} to="/">
              <img src={logo} alt="Le logo de l'association Roll In' Ternet" />
            </NavLink>
          </li>
          <li>
            <ul className={classes.mainMenu}>
              <li>
                <FestivalDropdown />
              </li>
              <li>
                <ShopDropdown />
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : "")}
                  to="/about"
                >
                  À propos
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : "")}
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              {/* COMMENTED TO REMOVE API CALLS */}
              {/* {isAuthenticated && (
                <li>
                  <NavLink
                    to="/profil"
                    className={({ isActive }) =>
                      isActive ? classes.pinkLinkActive : classes.pinkLink
                    }
                  >
                    Mon compte
                  </NavLink>
                </li>
              )}
              {!isAuthenticated && (
                <li>
                  <button
                    className={classes.connectionButton}
                    onClick={loginHandler}
                  >
                    Connexion
                  </button>
                </li>
              )} */}
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
