import MerchandisesCategories from "./MerchandisesCategories";

const MerchandisesList = (props) => {
  let sortedMerchList;

  const compareCategories = (catA, catB) => {
    if (catA[0].toLowerCase() < catB[0].toLowerCase()) return -1;

    return 1;
  };

  if (props.merchandises) {
    sortedMerchList = Object.entries(props.merchandises).sort(
      compareCategories
    );
  }

  return (
    <>
      {props.merchandises &&
        sortedMerchList.map(([type, list]) => (
          <MerchandisesCategories key={type} type={type} merchandises={list} />
        ))}
    </>
  );
};

export default MerchandisesList;
