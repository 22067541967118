import { NavLink } from "react-router-dom";

import { isUserAdmin } from "providers/atoms/auth";
import { useRecoilValue } from "recoil";
import classes from "./Footer.module.css";

const Footer = () => {
  const isAdmin: boolean = useRecoilValue(isUserAdmin);

  return (
    <footer className={classes.footer}>
      <nav className={classes.nav}>
        <ul>
          <li>
            <NavLink to="/">Roll In' Ternet ©</NavLink>
          </li>
          <li>
            <ul className={classes.right}>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li className={classes.mgLeft}>
                <NavLink to="/mentions">Mentions</NavLink>
              </li>
              {isAdmin && (
                <li className={classes.mgLeft}>
                  <NavLink to="/admin">Page d'administration</NavLink>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
