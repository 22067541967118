import Card from "components/UI/Card";

import classes from "./Merchandise.module.css";

const Merchandise = (props) => {
  const img = props.merch.img_path;
  const price =
    props.merch.price.toFixed(2).replace(".00", "").replace(".", ",") + " €";

  return (
    <Card className={classes.merch}>
      <div className={classes.merchHeaderContainer}>
        <div className={classes.merchHeader}>
          <div className={classes.name}>
            {props.merch.name} {props.merch.available ? "" : "(épuisé)"}
          </div>

          <div className={classes.price}>{price}</div>
        </div>
      </div>
      <div className={classes.imgContainer}>
        <img src={img} loading="lazy" alt={"Image de " + props.merch.name} />
      </div>
    </Card>
  );
};

export default Merchandise;
