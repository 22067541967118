import Card from "components/UI/Card";
import MainCard from "components/UI/MainCard";
import useDocumentTitle from "hooks/use-document-title";

import classes from "./About.module.css";

const About = () => {
  useDocumentTitle("À propos - Roll In' Ternet");

  return (
    <MainCard>
      <h1>Qui sommes-nous&nbsp;?</h1>
      <Card className={classes.about}>
        <h2>🌺 Notre mission</h2>{" "}
        <p>
          Permettre au monde de se retrouver le temps d'un week-end autour du
          jeu de rôle, dans une bonne ambiance et avec des gens sympas. Vous
          permettre de passer un bon moment, et diffuser du bonheur et du
          plaisir&nbsp;!
        </p>
      </Card>
      <Card className={classes.about}>
        <h2>🌿 Nos valeurs</h2>{" "}
        <p>
          Roll In' Ternet s'articule autour de quatre valeurs essentielles, qui
          guident l'organisation et le projet&nbsp;: le plaisir, l'inclusivité,
          le partage et le bien-être&nbsp;!
        </p>
      </Card>
      <Card className={classes.about}>
        <h2>💜 Notre histoire</h2>{" "}
        <p>
          Roll In' Ternet est né pendant le premier confinement de l'année 2020,
          dans la tête de Shanori. L'idée&nbsp;? A défaut de pouvoir sortir et
          s'adonner à nos loisirs IRL habituels, créer un événement qui permette
          à des ami.e.s de se réunir pendant un week-end pour faire du jeu de
          rôle.
        </p>{" "}
        <p>
          Ainsi a eu lieu le festival, les 13 et 14 juin 2020, après un mois et
          demi de préparation et une organisation composée de trois personnes.
          Le festival n'était alors accessible que par invitation (les ami.e.s,
          les ami.e.s d'ami.e.s, ...) (et la charte graphique était
          verte&nbsp;!).
        </p>{" "}
        <p>
          Mais, l'événement a été un succès inattendu&nbsp;! Suite aux retours
          (♥), il a donc été décidé d'organiser une seconde édition et
          d'agrandir l'équipe&nbsp;: neuf personnes, trois mois et demi de
          préparation et une édition les 14 et 15 novembre 2020. L'édition sera
          de la couleur violette&nbsp;!
        </p>{" "}
        <p>
          Jamais deux sans trois comme on dit, Roll In' Ternet est donc revenu
          pour une troisième édition, les 12 et 13 juin 2021. Cette fois-ci,
          l'équipe sera constituée de 13 personnes, et l'organisation prendra
          quatre mois et demi. Il est décidé que le violet sera la couleur
          officielle de Roll In' Ternet. Mais surtout cette édition marque un
          tournant dans l'histoire du festival, car le 22 février 2021, nous
          devenons une association&nbsp;!
        </p>{" "}
        <p>
        En 2022, on est revenu.e.s avec une nouvelle édition estivale, les 11
        et 12 juin, en mode summer miami vibes&nbsp;! Avec 16 personnes dans
        l'équipe d'orga', et la création d'une équipe de modération&nbsp;!!
        </p>{" "}
        <p>
        En 2023, le festival a eu lieu pour la première fois
        sur trois jours, du vendredi 26 mai à 20h30 au dimanche 28 mai&nbsp;!
        </p>
      </Card>
    </MainCard>
  );
};

export default About;
