import { Route, Routes } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ScrollToTop from "components/Helpers/ScrollToTop";
import { SnackbarProvider } from "components/Helpers/SnackbarProvider";
import moment from "moment-timezone";
import "moment/locale/fr";
import Downloads from "pages/Downloads";
import Previous from "pages/Previous";
import { isUserAdmin, isUserAuthenticated } from "providers/atoms/auth";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { TIMEZONE } from "utils/dates";
import AuthProvider from "./components/Helpers/AuthProvider";
import ProtectedRoute from "./components/Helpers/ProtectedRoute";
import Layout from "./components/Layout/Layout";
import About from "./pages/About";
import Account from "./pages/Account";
import Admin from "./pages/Admin";
import Contact from "./pages/Contact";
import Donations from "./pages/Donations";
import Faq from "./pages/Faq";
import Homepage from "./pages/Homepage";
import Mentions from "./pages/Mentions";
import NotFound from "./pages/NotFound";
import Program from "./pages/Program";
import Shop from "./pages/Shop";
import Tables from "./pages/Tables";

function App() {
  const isAuthenticated: boolean = useRecoilValue(isUserAuthenticated);
  const isAdmin: boolean = useRecoilValue(isUserAdmin);

  moment.tz.setDefault(TIMEZONE);
  moment.locale("fr");

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="fr">
      <Suspense fallback={<div>Loading... </div>}>
        <SnackbarProvider>
          <AuthProvider>
            <Layout>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/donations" element={<Donations />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/mentions" element={<Mentions />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/program" element={<Program />} />
                <Route path="/downloads" element={<Downloads />} />
                {/* COMMENTED TO REMOVE API CALLS */}
                {/* <Route path="/previous" element={<Previous />} />
                <Route path="/tables" element={<Tables />} />
                <Route
                  path="/profil"
                  element={
                    <ProtectedRoute isAuthorized={isAuthenticated}>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute isAuthorized={isAdmin}>
                      <Admin />
                    </ProtectedRoute>
                  }
                /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          </AuthProvider>
        </SnackbarProvider>
      </Suspense>
    </LocalizationProvider>
  );
}

export default App;
