import moment from "moment";
import { EventTimes } from "types/RollInEvent";

export const TIMEZONE = "Europe/Paris";

export const convertEventTimes = ({ start_time, end_time }: EventTimes) => {
  return {
    start_time: convertTime(start_time),
    end_time: convertTime(end_time),
  };
};

export const convertTime = (time: Date) => {
  return moment.tz(time, TIMEZONE);
};

export const formatDate = ({ start_time, end_time }: EventTimes) => {
  const { start_time: startTimeConverted, end_time: endTimeConverted } =
    convertEventTimes({
      start_time,
      end_time,
    });

  let date = startTimeConverted.format("dddd D MMMM");
  date = date.charAt(0).toUpperCase() + date.slice(1);

  const startMinutes =
    (startTimeConverted.minutes() < 10 ? "0" : "") +
    startTimeConverted.minutes();
  const formattedStartTime = startTimeConverted.hours() + "h" + startMinutes;

  const endMinutes =
    (endTimeConverted.minutes() < 10 ? "0" : "") + endTimeConverted.minutes();
  const formattedEndTime = endTimeConverted.hours() + "h" + endMinutes;

  const dateFormatted =
    date + " • " + formattedStartTime + "-" + formattedEndTime;

  return {
    day: date,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    dateFormatted,
  };
};

export const formatDateActivity = ({ start_time, end_time }: EventTimes) => {
  const { start_time: startTimeConverted, end_time: endTimeConverted } =
    convertEventTimes({
      start_time,
      end_time,
    });

  let startDate = startTimeConverted.format("dddd D MMMM");
  startDate = startDate.charAt(0).toUpperCase() + startDate.slice(1);
  let endDate = endTimeConverted.format("dddd D MMMM");
  endDate = endDate.charAt(0).toUpperCase() + endDate.slice(1);

  const startMinutes =
    (startTimeConverted.minutes() < 10 ? "0" : "") +
    startTimeConverted.minutes();
  const formattedStartTime = startTimeConverted.hours() + "h" + startMinutes;

  const endMinutes =
    (endTimeConverted.minutes() < 10 ? "0" : "") + endTimeConverted.minutes();
  const formattedEndTime = endTimeConverted.hours() + "h" + endMinutes;

  let dateFormatted =
    startDate + " • " + formattedStartTime + "-" + formattedEndTime;

  if (startDate !== endDate) {
    dateFormatted =
      startDate + " • " + formattedStartTime + " - " + endDate + " • " + formattedEndTime
  }

  return {
    day: startDate,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    dateFormatted,
  };
};
